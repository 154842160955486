import React, { FC } from 'react';
import classNames from 'classnames';
import { SiteMapPage } from '@shared/types/umbraco';
import Button from 'common/Button';
import SitemapCategoryItem from './SitemapCategoryItem';

const SitemapCategory: FC<SiteMapPage.ICategory> = ({
  pageType = 'category',
  path,
  pageName,
  ariaLabel,
  subCategories,
  useRedirects,
}) => {
  const subCategoriesArr = Object.values(subCategories);

  return (
    <>
      {useRedirects ? (
        <a
          className={classNames(
            'btn',
            'btn--link',
            'btn--redirect',
            `sitemap__${pageType}__title`,
            {
              single: subCategoriesArr.length === 0,
            }
          )}
          href={path}
          aria-label={ariaLabel}
        >
          {pageName}
        </a>
      ) : null}
      {!useRedirects ? (
        <Button
          classes={classNames(`sitemap__${pageType}__title`, {
            single: subCategoriesArr.length === 0,
          })}
          variant="link"
          to={path}
          activeClassName="active"
          ariaLabel={ariaLabel}
        >
          {pageName}
        </Button>
      ) : null}
      {subCategoriesArr?.length ? (
        <ul className={`sitemap__${pageType}__list`}>
          {subCategoriesArr.map((category) => (
            <SitemapCategoryItem key={category.id} classes="subcategory" {...category} />
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default SitemapCategory;
