const createMainGroups = (pages, isMainLanguage) => {
  const result = {};

  const defaultPass = isMainLanguage ? '/' : pages[0].path.split('/').filter(Boolean)[0];

  pages.forEach((page) => {
    const nestingArr = page.path.split('/').filter(Boolean);
    const nestingArrLength = nestingArr.length;

    const pageObj = {
      id: page.id,
      path: page.path,
      pageName: page.context.pageName,
      ariaLabel: page.context.pageName,
      subCategories: {},
      useRedirects: page.context.useRedirects,
    };

    if (nestingArrLength === (isMainLanguage ? 0 : 1)) {
      result[defaultPass] = pageObj;
    }
    if (nestingArrLength === (isMainLanguage ? 1 : 2)) {
      result[defaultPass].subCategories = {
        ...result[defaultPass].subCategories,
        [nestingArr[isMainLanguage ? 0 : 1]]: pageObj,
      };
    }
    if (nestingArrLength === (isMainLanguage ? 2 : 3)) {
      if (nestingArr[isMainLanguage ? 0 : 1] in result[defaultPass].subCategories) {
        result[nestingArr[isMainLanguage ? 0 : 1]] = {
          ...result[defaultPass].subCategories[nestingArr[isMainLanguage ? 0 : 1]],
        };
        delete result[defaultPass].subCategories[nestingArr[isMainLanguage ? 0 : 1]];
      }
    }
  });

  return result;
};

const createPagesCategory = (pages, parentKey, parentPath) => {
  const result = {};
  pages.forEach((page) => {
    const nestingArr = page.path.split('/').filter(Boolean);

    const parentIndex = parentPath.split('/').filter(Boolean).length;
    const checkArr = nestingArr.slice(parentIndex);
    const categoryKey = checkArr[0];

    if (checkArr.length === 1) {
      result[categoryKey] = {
        id: page.id,
        path: page.path,
        pageName: page.context.pageName,
        ariaLabel: `${parentKey?.split('-').join(' ')} ${page.context.pageName}`,
        subCategories: {},
        useRedirects: page.context.useRedirects,
      };
    } else if (checkArr.length === 2) {
      const check = checkArr.join('/');
      const filteredByCategory = pages.filter((item) => item.path.includes(check));

      if (result[categoryKey]) {
        result[categoryKey] = {
          ...result[categoryKey],
          subCategories: {
            ...result[categoryKey].subCategories,
            ...createPagesCategory(filteredByCategory, categoryKey, result[categoryKey].path),
          },
        };
      } else {
        result[categoryKey] = {
          id: page.id,
          path: page.path,
          pageName: page.context.pageName,
          ariaLabel: `${parentKey?.split('-').join(' ')} ${page.context.pageName}`,
          subCategories: createPagesCategory(filteredByCategory, categoryKey, page.path),
        };
      }
    }
  });

  return result;
};

const createResult = (arr, filteredPages, isMainLanguage) =>
  Object.keys(arr).map((key, index) => {
    if (index === 0) return arr[key];
    const filteredByCategory = filteredPages.filter((item) => {
      const nestingArr = item.path.split('/').filter(Boolean);

      return (
        nestingArr.length > (isMainLanguage ? 1 : 2) && nestingArr[isMainLanguage ? 0 : 1] === key
      );
    });

    return {
      ...arr[key],
      subCategories: createPagesCategory(filteredByCategory, key, arr[key].path),
    };
  });

export const formSitemapList = (allPages, excludedPages) => {
  const excludedPagesWithDefault = [
    ...excludedPages,
    { name: '(404.html)', url: '/404.html' },
    { name: '(404 dev)', url: '/dev-404-page/' },
    { name: '404', url: '/404/' },
    { name: '(404)', url: '/404' },
  ];
  const filteredPages = allPages
    .filter((page) => {
      if (
        excludedPagesWithDefault.some(
          (exclPage) =>
            page.path === exclPage.url || page.path.includes(exclPage.name.toLowerCase())
        )
      ) {
        return false;
      }
      if (page.context.seoNoIndex) {
        const isShowInSiteMap =
          page.context.seoAdditionalMeta?.find((el) => el.key === 'sitemap')?.value === 'include';
        if (isShowInSiteMap) {
          return true;
        }

        return false;
      }

      return true;
    })
    .sort((a, b) => a.path.split('/').length - b.path.split('/').length);

  const isMainLanguage = filteredPages?.[0]?.path === '/';

  const mainGroups = createMainGroups(filteredPages, isMainLanguage);

  return createResult(mainGroups, filteredPages, isMainLanguage);
};
