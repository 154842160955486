import React, { FC } from 'react';
import Button from 'common/Button';
import { SiteMapPage } from '@shared/types/umbraco';

const SitemapCategoryItem: FC<SiteMapPage.ICategoryItem> = ({
  classes,
  path,
  pageName,
  ariaLabel,
  subCategories,
  useRedirects,
}) => {
  const subCategoriesArr = Object.values(subCategories);

  return (
    <li className={`${classes}__item`}>
      {useRedirects ? (
        <a
          className={`${classes}__subtitle btn btn--link btn-redirect`}
          href={path}
          aria-label={ariaLabel}
        >
          {pageName}
        </a>
      ) : null}
      {!useRedirects ? (
        <Button
          classes={`${classes}__subtitle`}
          variant="link"
          to={path}
          activeClassName="active"
          ariaLabel={ariaLabel}
        >
          {pageName}
        </Button>
      ) : null}
      {subCategoriesArr?.length ? (
        <ul className={`${classes}__list`}>
          {subCategoriesArr.map((category) => (
            <SitemapCategoryItem
              key={category.id}
              {...category}
              classes={classes === 'subsubcategory' ? 'subsubsubcategory' : 'subsubcategory'}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};
export default SitemapCategoryItem;
