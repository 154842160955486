import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { SiteMapPage } from '@shared/types/umbraco';
import { Container, Layout } from 'layout';
import SitemapCategory from 'components/SitemapCategory';
import SimpleBanner from 'components/SimpleBanner';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import SignUp from 'components/SignUp';
import './sitemap.scss';
import { formSitemapList } from 'utils/formSitemapList';

const elements = {
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
};

const SitemapPage: FC<SiteMapPage.IProps> = ({
  pageContext: { breadCrumbs, link, searchUrl },
  data,
}) => {
  const defaultCompositions = data?.sitemap?.defaultCompositions;
  const banner = data?.sitemap?.topBanner;
  const body = data?.sitemap?.body;
  const allPages = data?.allSitePage?.nodes;
  const excludedPages = data?.sitemap?.excludedPages;
  const {
    seoMetaTitle,
    seoMetaTitleTemplate,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    seoNoIndex,
    seoCanonicalUrl,
    alternateUrls,
    ogImageUrl,
  } = data?.sitemap;
  const { singupFormBaner, waiSettings } = defaultCompositions;

  const categories = formSitemapList(allPages, excludedPages);
  const imageUrl = ogImageUrl || banner?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0]?.properties?.title;

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        seoNoIndex,
        seoCanonicalUrl,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl,
        imageAlt,
      }}
    >
      {banner?.length ? <SimpleBanner {...{ ...banner[0].properties, breadCrumbs }} /> : null}
      <Container fluid className="sitemap">
        <ul className="sitemap__category-list">
          {categories?.map((catItem) =>
            catItem?.pageName ? (
              <li key={catItem.id} className="sitemap__category-list__item">
                <SitemapCategory {...catItem} />
              </li>
            ) : null
          )}
        </ul>
      </Container>
      <BodyRenderer
        bodyData={body}
        bodyStructure={elements}
        bodyItemProps={{ ...{ singupFormBaner } }}
      />
    </Layout>
  );
};

export default SitemapPage;

export const query = graphql`
  query ($link: String = "", $lang: String) {
    sitemap(link: { eq: $link }) {
      seoMetaTitle
      seoMetaTitleTemplate
      seoMetaDescription
      seoMetaKeywords
      seoNoIndex
      seoExternalHreflangs {
        key
        value
      }
      seoCanonicalUrl {
        url
      }
      alternateUrls {
        lang
        url
        path
      }
      ogImageUrl
      excludedPages {
        name
        url
      }
      topBanner {
        properties {
          description
          title
          titleSize
          titleSizeMobile
        }
      }
      body {
        ... on TSignupPlaceholder {
          ...FragmentSignUp
        }
      }
      defaultCompositions {
        footer {
          ...FragmentFooter
        }
        header {
          ...FragmentHeader
        }
        siteSettings {
          ...FragmentSiteSettingsComposition
        }
        brandSettings {
          ...FragmentBrandSettingsComposition
        }
        singupFormBaner {
          ...FragmentSignUpBanner
        }
        waiSettings {
          skipText
        }
        warning {
          ...FragmentWarning
        }
        signUpPopup {
          ...FragmentSignUpPopup
        }
        signUpFormPopup {
          ...FragmentSignUpFormPopup
        }
        promoPlatformForm {
          ...FragmentPromoPlatformForm
        }
      }
    }
    allSitePage(filter: { context: { lang: { eq: $lang } } }) {
      nodes {
        id
        path
        context {
          pageName
          useRedirects
          seoAdditionalMeta {
            key
            value
          }
          seoNoIndex
        }
      }
    }
  }
`;
